import React, {useLayoutEffect, useState} from "react"
import { Parallax, ParallaxLayer } from '../components/Parallax';
import Layout from "../components/Layout";
import TitleCard from "../components/TitleCard";
import StandardScrollSection from "../components/StandardScrollSection";
import ReviewsStars from "../components/ReviewsStars";
import Ending from "../components/Ending";
import EndingMenuItem from "../components/EndingMenuItem";
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { SEO } from "../components/SEO"

import {chaseGoldSlide, dropInDrop, showOutAppear, chopItUpType, victorsAppear} from "../helpers/animations";

const RenegadePage = () => {

  const [color, setColor] = useState("blue");
  const [itineraryLinkVisible, setitineraryLinkVisible] = useState(true);

  const caseGoldLink = "https://visitsunvalley.com/event/bald-mountain-tour/";
  const dropInLink = "https://www.sunvalleyheliski.com/";
  const showOutLink = "https://visitsunvalley.com/winter/your-guide-to-snowmobiling-in-the-sun-valley-idaho-area/";
  const chopItUpLink = "https://visitsunvalley.com/lodging/limelight-hotel-ketchum/";
  const victorsLink = "https://visitsunvalley.com/dining-shopping/the-covey/";

  const title = <>Welcome to<br/>Sun Valley, ID</>
  const itineraryLink = "/VSV_Winter_Itinerary_Renegades.pdf";
  const subTitle = "Scroll sideways to plan your outdoor adventure today.";
  const altTitle = <>Renegades<br/>Rendezvous</>

  return (
      <Layout color={color} itineraryLinkVisible={itineraryLinkVisible} itineraryLink={itineraryLink}>
        <Parallax pages={17.5} mobilePages={17.5} style={{ top: '0', left: '0' }}>
          <ParallaxLayer offset={0} speed={1}>
            <TitleCard title={title} altTitle={altTitle} instructions={subTitle} instructionsColor="blue" imgSrc="/VSV_LP_Renegades.jpg" />
          </ParallaxLayer>

          <ParallaxLayer offset={1} speed={1}>
            <div className="ending-menu toc">
              <EndingMenuItem text="Downhill" scrollTo="chase-gold" duration=".5"/>
              <EndingMenuItem text="Heliski" scrollTo="drop-in" duration="1"/>
              <EndingMenuItem text="Snowmobile" scrollTo="show-out" duration="1.5"/>
              <EndingMenuItem text="Lodge" scrollTo="soak-it-up" duration="2"/>
              <EndingMenuItem text="Feast" scrollTo="to-the-victors" duration="2.5"/>
            </div>
          </ParallaxLayer>

          <StandardScrollSection
            id="chase-gold"
            offset={1.5}
            setNavColor={setColor}
            bgColor="blue"
            titleColor="gold"
            title="Chase Gold"
            content={<>Ski Sun Valley’s <OutboundLink href={caseGoldLink} target="_blank">Bald Mountain Resort,</OutboundLink> ranked the #1 mountain in the west by SKI magazine. Imagine 3400’ vertical feet of down the fall line elevation change. Uncrowded. Unspoiled. And beloved by Olympians, past and present.</>}
            intoImg='/VSV_LandingPage_Renegades_02.jpg'
            backgroundSvg='/renegades_bg_01.svg'
            foregroundImg='/VSV_LandingPage_Renegades_03.png'
            trailImg='/VSV_LandingPage_Renegades_04.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.to(imageRef.current, {x:"-40%", y:"-20%", ease: "none"})
            }}
            titleAnimationFunc={chaseGoldSlide}
            />

          <StandardScrollSection
            id="drop-in"
            offset={4.5}
            setNavColor={setColor}
            bgColor="red"
            titleColor="brown"
            title="Drop In"
            content={<><OutboundLink href={dropInLink} target="_blank">Sun Valley Heli Ski</OutboundLink> gives you access to 750,000 acres of powder across three different mountain ranges. Experienced certified guides will help you find the day’s best conditions and, hopefully, countless face shots.</>}
            intoImg='/VSV_LandingPage_Renegades_05.jpg'
            backgroundSvg='/renegades_bg_02.svg'
            foregroundImg='/VSV_LandingPage_Renegades_06.png'
            trailImg='/VSV_LandingPage_Renegades_07.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.to(imageRef.current, {x:"-70%", y:"-40%", ease: "none"})
            }}
            titleAnimationFunc={dropInDrop}
            />

          <StandardScrollSection
            id="show-out"
            offset={7.5}
            setNavColor={setColor}
            bgColor="gold"
            titleColor="red"
            title="Show Out"
            content={<>At some point on the trip, you’re gonna want to <OutboundLink href={showOutLink} target="_blank">get loose on a snowmobile.</OutboundLink> Traverse hundreds of miles of groomed trails or go deep into the wide open backcountry. However you ride, please be a good steward to the sport and know where to go.</>}
            intoImg='/VSV_LandingPage_Renegades_08.jpg'
            backgroundSvg='/renegades_bg_03.svg'
            foregroundImg='/VSV_LandingPage_Renegades_09.png'
            trailImg='/VSV_LandingPage_Renegades_10.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.fromTo(imageRef.current, {x:"-70%", scale: .5, y: "20%", ease: "none"}, {x:"-20%", scale: 1, y:"0"})
            }}
            titleAnimationFunc={showOutAppear}
            />

            <StandardScrollSection
              id="soak-it-up"
              offset={10.5}
              setNavColor={setColor}
              bgColor="brown"
              titleColor="white"
              linksColor="red"
              title="SOAK IT UP"
              content={<>Regale in the tales of the day by soaking in the outdoor hot tubs at <OutboundLink href={chopItUpLink} target="_blank">The Limelight.</OutboundLink> Located in the heart of Sun Valley, this is the perfect lodging for the Renegade Rendezvous. </>}
              intoImg='/VSV_LandingPage_Renegades_11.jpg'
              backgroundSvg='/renegades_bg_04.svg'
              foregroundImg='/VSV_Winter_Renegades_Soakitup_Isolated.png'
              trailImg='/VSV_Winter_Renegades_Soakitup.jpg'
              imageAnimationFunc={(imageRef)=>(tl)=>{
                tl.fromTo(imageRef.current, { scale: .5, y: "20%", x:"-19%", ease: "none"}, { scale: .9, ease: "none"})
              }}
              titleAnimationFunc={chopItUpType}
              />

            <StandardScrollSection
              id="to-the-victors"
              offset={13.5}
              setNavColor={setColor}
              bgColor="red"
              titleColor="blue"
              title={<>To The<br/>Victors</>}
              content={<>Sample some of the Valley’s finest cuts at, <OutboundLink href={victorsLink} target="_blank">The Covey.</OutboundLink> Great vibe, great wine, great friends. Eat like royalty, Renegade. You’ve earned it.</>}
              intoImg='/VSV_LandingPage_Renegades_14.jpg'
              backgroundSvg='/renegades_bg_05.svg'
              foregroundImg='/VSV_LandingPage_Renegades_15.png'
              trailImg='/VSV_LandingPage_Renegades_16.jpg'
              imageAnimationFunc={(imageRef)=>(tl)=>{
                tl.fromTo(imageRef.current, { scale: .6, y: "40%", x:"5%", ease: "none"}, { scale: .9, y: "-15%", ease: "none"})
              }}
              titleAnimationFunc={victorsAppear}
              />

            <ReviewsStars offset={16.5} rating={4.2} />

        </Parallax>
      </Layout>
    );
}

export default RenegadePage

export const Head = () => (
  <SEO title="Renegades, Sun Valley Wants You" image="/SEO_VSV_LandingPage_Renegades_01.jpg" description="Are you a ski afficionado in search of adrenaline? Sun Valley, ID wants you."/>
)
